<template>
    <Licensees/>
</template>

<script>
import Licensees from '@/components/Licensees'

export default {
   components: {
       Licensees
   } 
}
</script>
