<template>
    <div class="section" style="min-height: 400px">
        <h1>Специалисты</h1>
        <iframe style="width: 100%; height: 50vh" src="https://api.cmks.online/widgets/get-widget?guid=bNbZyVGyQ9ig1evQzAyb-ajPO&cid=cOypcPHsDE_yN4Lk_hKXPs-qSEmg44xaXW9eWGFA_v5MFE6jLz" frameborder="0"></iframe>
    </div>
</template>

<script>
export default {

}
</script>
