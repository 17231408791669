<template>
    <div class="section" style="min-height: 400px">
        <h1>Акции</h1>
        <div :key="promotion.id" v-for="promotion in getStatic.promotions">
            <h2>{{promotion.name}}</h2>
            <p v-html="promotion.content"></p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
        ...mapGetters([
            'getStatic'
        ])
    },
}
</script>

<style scoped>

</style>
