<template>
    <Contacts/>
</template>

<script>
import Contacts from '@/components/Contacts'

export default {
   components: {
       Contacts
   } 
}
</script>
